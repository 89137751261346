import axios from '../plugins/axios';
import router from '../router';

const auth = {
  state: {
    authorized: !!JSON.parse(localStorage.getItem('authorized')),
    accessToken: localStorage.getItem('user-token') || '',
    refreshToken: localStorage.getItem('refresh-token') || ''
  },
  mutations: {
    setAuthorized( state ) {
      state.authorized = true;
    },
    notAuthorized( state ) {
      state.authorized = false;
    },
    registerSuccess(){
      router.push({name:'registerMail'});
    }
  },
  actions: {
    authUser({ commit }, user) {
      const form = convertObjectToForm(user);
      return new Promise((resolve, reject) => {
        axios.post('/login', form)
          .then(resp => {
            if (resp.status == 200) {

              axios.defaults.headers.common['authorization'] = 'Bearer ' + resp.data.access_token;
              localStorage.setItem('user-token', resp.data.access_token);
              localStorage.setItem('refresh-token', resp.data.refresh_token);
              localStorage.setItem('token-expires-in', calculateRemainingTime(resp.data.expires_in));
              localStorage.setItem('refresh-expires-in', calculateRemainingTime(resp.data.refresh_expires_in));
              localStorage.setItem('authorized', true);

              commit('setAuthorized');
            }
            resolve(resp);
          })
          .catch(err => {
            clearStorage();

            reject(err);
          });
      });
    },
    async authRegister({ dispatch }, user) {
      const form = convertObjectToForm(user);
      try{
        let response = await axios.post('/register', form);
        if(response.status == 200){
          dispatch('registerSuccess');
        }
      }catch(e){
        console.log('failed');
        return 'failed';
      }
    },
    registerSuccess({commit}){
      commit('registerSuccess');
    },
    authExpired({ commit }) {
      clearStorage();
      commit('notAuthorized');
    },
    authLogout({ commit }) {
      return new Promise((resolve, reject) => {
        axios.get('/logout')
          .then(resp => {

            if(resp.status == 200) {
              clearStorage();
              commit('notAuthorized');

              resolve();
            }
          })
          .catch(() => reject());
      });
    },
    deleteAccount({ commit }){
      return new Promise((resolve, reject) => {
        axios.delete('/user')
          .then(resp => {
            if(resp.status == 200) {
              clearStorage();
              commit('notAuthorized');

              resolve();
            }
          })
          .catch(() => reject());
      });
    },
    refreshTokenFunc() {
      return new Promise((resolve, reject) => {
        axios.post('/check-sso', {
          refresh_token: localStorage.getItem('refresh-token')
        })
          .then(resp => {
            if (resp.status == 200) {

              axios.defaults.headers.common['authorization'] = 'Bearer ' + resp.data.access_token;
              localStorage.setItem('user-token', resp.data.access_token);
              localStorage.setItem('refresh-token', resp.data.refresh_token);
              localStorage.setItem('token-expires-in', calculateRemainingTime(resp.data.expires_in));
            }
            resolve(resp.status);
          })
          .catch(() => {
            localStorage.removeItem('user-token');
            localStorage.removeItem('refresh-token');
            localStorage.removeItem('user-roles');
            localStorage.setItem( 'authorized', false );
            reject();
          });
      });
    }
  },
  getters: {
    isAuthorized: (state) => state.authorized
  }
};

function convertObjectToForm(user){
  const form = new FormData();
  for(let key in user) {
    form.append(key, user[key]);
  }
  return form;
}

function calculateRemainingTime(expiry_duration) {
  return new Date().getTime() + ( (expiry_duration - 5) * 1000 ); // 5 seconds are a margin in case of any delays
}

function clearStorage() {
  localStorage.removeItem('user-token');
  localStorage.removeItem('refresh-token');
  localStorage.setItem('authorized', false);
  localStorage.removeItem('token-expires-in');
  localStorage.removeItem('refresh-expires-in');
  localStorage.removeItem('user-roles');
}

export default auth;
