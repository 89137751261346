export default {
  welcome: 'Holz-Handwerk Digital 2020',
  profile_title: 'Sie sind eingeloggt als',
  'forms': {
    login_body: 'Herzlich Willkommen bei der </br>' 
                +'Häfele Holz-Handwerk Digital 2020.</br>' 
                +'Bitte loggen Sie sich ein.\n',
    register: 'Registrieren',
    registration: 'Registrierung',
    registeration_success_heading: 'Ihre Registrierung war erfolgreich!',
    registeration_success_content: 'Ihre E-Mail wurde erfolgreich bestätigt.',
    register_success:'Vielen Dank für Ihre Registrierung. Eine Email von Häfele ist unterwegs zu Ihnen. Bitte bestätigen Sie Ihre Registrierung durch Klick auf den Link und prüfen Sie gegebenenfalls Ihren Spam-Ordner.',
    registration_body: 'Herzlich Willkommen bei der </br>'
                        +'Häfele Holz-Handwerk Digital 2020.</br>' 
                        +'Bitte registrieren Sie sich.',
    terms: 'Bitte bestätigen Sie die Datenschutzbestimmungen',
    recaptcha_protection: 'Diese Seite wird durch reCAPTCHA geschützt. Lesen Sie dazu die Google',
    privacy_policy: 'Datenschutzbestimmungen',
    accept_privacy: 'Hiermit bestätige ich die Datenschutzbestimmungen.',
    accept_privacy_full: 'Als Gegenleistung für die Teilnahme an der kostenlosen virtuellen Messe willige ich in die Nutzung meiner Kontaktdaten sowie der während des Messebesuchs per Merkliste gemerkten Produkte für eine personalisierte werbliche Ansprache per E-Mail durch die Häfele GmbH Co KG ein. Der Widerruf dieser Einwilligung ist jederzeit möglich. Mehr Details zu der Datenverarbeitung und meinen Rechten finden sich in der Datenschutzerklärung.',
    terms_of_services: 'Nutzungsbedingungen',
    and: 'und',
    apply: '',
    login: 'Login',
    login_title: 'Ihr Login',
    new_user: 'Noch kein Login? Registrieren Sie sich',
    here: 'HIER',
    already_user: 'Sie haben schon einen Login? Hier geht es',
    back: 'ZURÜCK',
    login_now: 'Jetzt einloggen',
    reset_password: 'Passwort vergessen',
    reset_password_body: 'Bitte geben Sie Ihre E-Mail-Adresse ein.',
    new_password: 'Neues Passwort',
    new_password_body: 'Geben Sie hier ein neues Passwort ein, um sich bei der Häfele Holz-Handwerk 2020 wieder anmelden zu können.',
    delete_account_body: 'Wollen Sie Ihren Account wirklich unwiderruflich löschen?',
    place_holders:{
      email: 'E-Mail-Adresse',
      first_name: 'Vorname',
      last_name: 'Nachname',
      password: 'Passwort',
      password_check:'Passwort wiederholen',
      confirm_password: 'Passwort wiederholen',
      company: 'Unternehmen',
      position: 'Position',
      plz:'Postleitzahl',
      new_password: 'Neues Passwort beantragen',
      set_password: 'Passwort zurücksetzen'
    },
    responses: {
      wrong_credentials: 'Sie haben einen ungültigen Nutzernamen oder Passwort eingegeben-',
      reset_success: 'Ihr Passwort wurde zurückgesetzt. Bitte prüfen Sie Ihre E-Mails und folgen Sie dem darin enthaltenen Link.',
      user_not_found: 'Email nicht gefunden!!',
      reset_done: 'Passwort erfolgreich zurückgesetzt!',
      token_expired: 'Das token ist abgelaufen!'
    }
  },
  'landing': {
    heading: 'Willkommen bei der Häfele Holz-Handwerk Digital 2020',
    date: 'Vom 24. - 27.06.2020',
    time: 'jeweils 9.00 Uhr - 18.00 Uhr',
    body: 'Bitte registrieren oder loggen Sie sich ein.',
    button: 'Jetzt registrieren',
    button_login: 'Jetzt einloggen'
  },
  notes: {
    no_notes: 'Merken Sie sich ein Produkt!<br /> Sie haben noch kein Produkt<br /> zur Merkliste hinzugefügt.',
    no_selected: 'Wählen Sie ein gemerktes Produkt aus der Liste links aus, <br />um sich zu diesem Notizen zu machen.<br />Falls die Liste leer ist,<br /> merken Sie sich zuerst ein Produkt aus dem Rundgang.'
  },
  imprint_title: 'Impressum',
  imprint_body:'Datenschutzerklärung\n'
               +'VerantwortlicheStelle im Sinne der Datenschutzgesetze, insbesondere der EU-Datenschutzgrundverordnung (DSGVO), ist:\n \n'
               +'Herausgeber\nb.ReX GmbH\nReinsburgstr.\n'
               +'96/1 70197 Stuttgart\nTelefon: + 49 (0) 711 993375-57\nTelefax: + 49 (0) 711 993375-55\n'
               +'E-Mail: kommunikation@b-rex.de\n\n'
               +'Vertretungsberechtigte Geschäftsführer\nMaximilian Schmierer\nThomas Elser\nAndreas Hornung\n\n'
               +'Sitz der Gesellschaft: Stuttgart\nEingetragen beim Handelsregister des Amtsgericht Stuttgart / HRB 763700\n'
               +'Umsatzsteuer-ID-Nr. gem. § 27a UStG: DE316729408\n\n'
               +'Inhaltlich verantwortlich\nMaximilian Schmierer\nThomas Elser\nAndreas Hornung\n\n'
               +'Ihre Betroffenenrechte\nUnter den angegebenen Kontaktdaten unseres Datenschutzbeauftragten können Sie jederzeit folgende Rechte ausüben:\n\n'
               +'Auskunft über Ihre bei uns gespeicherten Daten und deren Verarbeitung,\n'
               +'Berichtigung unrichtiger personenbezogener Daten,\n'
               +'Löschung Ihrer bei uns gespeicherten Daten,\n'
               +'Einschränkung der Datenverarbeitung, sofern wir Ihre Daten aufgrund gesetzlicher Pflichten noch nicht löschen dürfen,\n'
               +'Widerspruch gegen die Verarbeitung Ihrer Daten bei uns und\n'
               +'Datenübertragbarkeit, sofern Sie in die Datenverarbeitung eingewilligt haben oder einen Vertrag mit uns abgeschlossen haben.\n'
               +'Sofern Sie uns eine Einwilligung erteilt haben, können Sie diese jederzeit mit Wirkung für die Zukunft widerrufen.\n\n'
               +'Sie können sich jederzeit mit einer Beschwerde an die für Sie zuständige Aufsichtsbehörde wenden. Ihre zuständige Aufsichtsbehörde richtet sich nach dem Bundesland Ihres Wohnsitzes, Ihrer Arbeit oder der mutmaßlichen Verletzung. Eine Liste der Aufsichtsbehörden (für den nichtöffentlichen Bereich) mit Anschrift finden Sie unter: https://www.bfdi.bund.de/DE/Infothek/Anschriften_Links/anschriften_links-node.html.\n\n'
               +'Zwecke der Datenverarbeitung durch die verantwortliche Stelle und Dritte\n'
               +'Wir verarbeiten Ihre personenbezogenen Daten nur zu den in dieser Datenschutzerklärung genannten Zwecken. Eine Übermittlung Ihrer persönlichen Daten an Dritte zu anderen als den genannten Zwecken findet nicht statt. Wir geben Ihre persönlichen Daten nur an Dritte weiter, wenn:\n\n'
               +'Sie Ihre ausdrückliche Einwilligung dazu erteilt haben,\n'
               +'die Verarbeitung zur Abwicklung eines Vertrags mit Ihnen erforderlich ist,\n'
               +'die Verarbeitung zur Erfüllung einer rechtlichen Verpflichtung erforderlich ist,\n'
               +'die Verarbeitung zur Wahrung berechtigter Interessen erforderlich ist und kein Grund zur Annahme besteht, dass Sie ein überwiegendes schutzwürdiges Interesse an der Nichtweitergabe Ihrer Daten haben.\n\n'
               +'Löschung bzw. Sperrung der Daten\n'
               +'Wir halten uns an die Grundsätze der Datenvermeidung und Datensparsamkeit. Wir speichern Ihre personenbezogenen Daten daher nur so lange, wie dies zur Erreichung der hier genannten Zwecke erforderlich ist oder wie es die vom Gesetzgeber vorgesehenen vielfältigen Speicherfristen vorsehen. Nach Fortfall des jeweiligen Zweckes bzw. Ablauf dieser Fristen werden die entsprechenden Daten routinemäßig und entsprechend den gesetzlichen Vorschriften gesperrt oder gelöscht.\n\n'
               +'Erfassung allgemeiner Informationen beim Besuch unserer Website\n'
               +'Wenn Sie auf unsere Website zugreifen, werden automatisch mittels eines Cookies Informationen allgemeiner Natur erfasst. Diese Informationen (Server-Logfiles) beinhalten etwa die Art des Webbrowsers, das verwendete Betriebssystem, den Domainnamen Ihres Internet-Service-Providers und ähnliches. Hierbei handelt es sich ausschließlich um Informationen, welche keine Rückschlüsse auf Ihre Person zulassen.\n\n'
               +'Diese Informationen sind technisch notwendig, um von Ihnen angeforderte Inhalte von Webseiten korrekt auszuliefern und fallen bei Nutzung des Internets zwingend an. Sie werden insbesondere zu folgenden Zwecken verarbeitet:\n\n'
               +'Sicherstellung eines problemlosen Verbindungsaufbaus der Website,\n'
               +'Sicherstellung einer reibungslosen Nutzung unserer Website,\n'
               +'Auswertung der Systemsicherheit und -stabilität sowie\n'
               +'zu weiteren administrativen Zwecken.\n'
               +'Die Verarbeitung Ihrer personenbezogenen Daten basiert auf unserem berechtigten Interesse aus den vorgenannten Zwecken zur Datenerhebung. Wir verwenden Ihre Daten nicht, um Rückschlüsse auf Ihre Person zu ziehen. Empfänger der Daten sind nur die verantwortliche Stelle und ggf. Auftragsverarbeiter.\n\n'
               +'Anonyme Informationen dieser Art werden von uns ggfs. statistisch ausgewertet, um unseren Internetauftritt und die dahinterstehende Technik zu optimieren.',
  privacy_title: 'Datenschutzerklärung',
  privacy_body: 'Datenschutz\n'
                +'Datenschutzerklärung\n'
                +'Sie befinden sich auf den Webseiten der Häfele GmbH & Co KG, <a href="http://www.haefele.de/"www.haefele.de</a>> oder <a href="http://www.hafele.com/"www.hafele.com</a>.> Der Schutz Ihrer Daten ist uns wichtig. Daher möchten wir Sie nachfolgend informieren, welche Daten Ihres Besuchs wir zu welchen Zwecken verwenden.<ol><li>Verantwortlicher</li></ol>Verantwortlicher im Sinne der Datenschutz-Grundverordnung (im Folgenden „DS-GVO") und sonstiger in den Mitgliedstaaten der Europäischen Union geltenden Bestimmungen zum Datenschutz ist die:\n'
                +'Häfele GmbH & Co KG (im Folgenden „Häfele“)Adolf-Häfele-Str. 172202 NagoldTel.: + 49( 0) 74 52 95 0Fax: + 49 (0) 74 52 95 2 00E-Mail: <a href="mailto:info@haefele.de?subject=Datenschutzerkl%C3%A4rung">info@haefele.de</a><ol start="2"><li>Kontaktdaten des Datenschutzbeauftragten</li></ol>Der Datenschutzbeauftragte bei Häfele ist unter folgenden Kontaktdaten erreichbar:\n',
  help_title: 'Hilfe zur Nutzung',
  help_mail: 'vertriebs-event@haefele.de',
  exhibitors: 'Berater',
  exhibits: 'Exponate',
  note_placeholder: 'Bitte geben Sie hier Ihre Notiz zum Produkt ein!',
  save: 'Speichern',
  share: 'Teilen',
  delete: 'Löschen',
  contacts_at_booth:'Auf dem Stand',
  active_contacts:'Begonnene Chats',
  sales_inquiry:'Vertriebsanfrage',
  my_area:'Auf dem Stand',
  note_saved:'Notiz erfolgreich gespeichert',
  note_error:'Fehler beim Speichern der Notiz',
  search: 'Suchen',
  search_our_products: 'Vom Kunden gemerkte Exponate',
  searching: {
    no_results: 'Nichts gefunden!</br>Es wurde kein Produkt zu diesem Suchbegriff gefunden.',
    no_selected: 'Wählen Sie ein Produkt aus der Liste links aus. </br>Falls die Liste leer ist, geben Sie einen anderen Suchbegriff ein.'
  },
  contact_note_placeholder: 'Ihre Notiz zu Diesem Kontakt',
  send: 'Senden',
  video: 'Video an',
  buttons: {
    product: 'Produktseite',
    offer: 'Vertriebskontakt',
    save: 'Speichern',
    share: 'Teilen als e-Mail',
    delete: 'Löschen',
    logout: 'Logout',
    delete_account: 'Account deaktivieren',
    add_to_watchlist: 'Zur Merkliste hinzufügen',
    go_to_shop:'Shop besuchen',
    contact_sales: 'Live Chat anfragen',
    download_pdf: 'Produktinfo (PDF)',
    cancel_account_deletion: 'Account nicht löschen',
    delete_account_confirmation: 'Account löschen',
    skip: 'Jetzt überspringen',
    VR: 'Jetzt virtuell entdecken'
  },
  placeholders: {
    note_body: 'Bitte geben Sie hier Ihre Notiz zum Produkt ein!'
  },
  start: 'Raum betreten',
  tutorial: 'Dieser Themenraum ist eine 360° Ansicht. Klicken Sie mit der Maus in den Raum und ziehen Sie die Maus von Links nach Rechts, um sich umzusehen. Klicken Sie einen der roten Kreise an, um Details zu einem Produkt aufzurufen. Über die Pfeile navigieren Sie durch den Raum.',
  product_description: 'Bildergalerie:',
  product_video:'Videogalerie:',
  threeD_model: '3D Modelle',
  visit_fair_now: 'Jetzt Messe virtuell besuchen',
  bussiness_card: 'Visitenkarte und Notizen zu',
  hafele_contact_persons: 'Ihre Ansprechpartner bei Häfele',
  hafele_exhibits: 'Alle Exponate von Häfele',
  mail_body: '{link}',
  visitors: 'Besucher',
  active: 'Unterhaltungen',
  me: 'Sie',
  recipient_not_online: 'Empfänger nicht online',
  anonymous: 'Während der Messeteilnahme haben Sie die Möglichkeit, Ihren Namen für andere Besucher anzeigen zu lassen, um so mit anderen Teilnehmern in persönlichen Kontakt treten zu können. Sie können diese Einstellung vor jedem Login und während des Besuchs in Ihrem persönlichen Profil jederzeit anpassen. Weitere Informationen finden Sie in der Datenschutzerklärung.',
  back: 'Zurück'
};
