import Vue from 'vue';
import VueRouter from 'vue-router';
import VueAnalytics from 'vue-analytics';
import axios from '../plugins/axios';
import store from '../store';

Vue.use(VueRouter);

const routes = [{
  path: '/',
  name: 'home',
  component: () => import('views/Home'),
  children: [
    {
      path: '',
      name: 'landing',
      component: () => import('views/auth/Landing')
    },
    {
      path: 'login',
      name: 'login',
      component: () => import('views/auth/LoginForm')
    },
    {
      path: 'register',
      name: 'register',
      component: () => import('views/auth/RegisterForm')
    },
    {
      path: 'register-mail',
      name: 'registerMail',
      component: () => import('views/auth/RegisterMail')
    },
    {
      path: 'registersuccess',
      name: 'registersuccess',
      component: () => import('views/auth/RegisterSuccess')
    },
    {
      path: 'reset-password',
      name: 'resetPassword',
      component: () => import('views/auth/ResetPassword')
    },
    {
      path: 'new-password',
      name: 'newPassword',
      component: () => import('views/auth/NewPassword')
    },
    {
      path: 'datenschutzerklaerung',
      name: 'datenschutzerklaerung',
      component: () => import('views/legal/Datenschutz')
    },
    {
      path: 'impressum',
      name: 'impressum',
      component: () => import('views/legal/Impressum')
    },
    {
      path: 'intro-Video',
      name: 'introVideo',
      component: () => import('views/IntroVideo')
    },
    {
      path: 'notes',
      name: 'notes',
      component: () => import('views/note/Notes'),
      meta: { requiresAuth: true },
      children: [
        {
          path: ':id',
          name: 'notesDetails',
          component: () => import('views/note/NotesDetails'),
          meta: { requiresAuth: true }
        }
      ]
    },
    {
      path: 'menu',
      name: 'menu',
      component: () => import('views/Menu'),
      meta: { requiresAuth: true }
    },
    {
      path: 'deepdive',
      name: 'deepdive',
      component: () => import('views/DeepDive'),
      meta: { requiresAuth: true }
    },
    {
      path: 'deepdiveslido',
      name: 'deepdiveSlido',
      component: () => import('views/DeepDiveSlido'),
      meta: { requiresAuth: true }
    },
    {
      path: 'deepdiveakademie',
      name: 'deepdiveAkademie',
      component: () => import('views/DeepDiveAkademie'),
      meta: { requiresAuth: true }
    },
    {
      path: 'plan',
      name: 'plan',
      component: () => import('views/Plan'),
      meta: { requiresAuth: true }
    },
    {
      path: 'chat/:user_id?',
      name: 'chat',
      component: () => import('components/shared/Chat.vue'),
      meta: { requiresAuth: true }
    },
    {
      path: 'exhibitor/:user_id?',
      name: 'ausstelleransicht',
      component: () => import('views/Ausstelleransicht'),
      meta: { requiresAuth: true }
    },
    {
      path: 'videochat/:user_uuid',
      name: 'videochat',
      component: () => import('views/VideoChat'),
      meta: { requiresAuth: true }
    },
    {
      path: 'krpano/:booth_uuid',
      name: 'krpano',
      component: () => import('views/Krpano'),
      meta: { requiresAuth: true }
    },
    {
      path: 'start-card',
      name: 'startCard',
      component: () => import('views/messestandansicht/StartCard'),
      meta: { requiresAuth: true }
    },
    {
      path: 'product/:product_id?',
      name: 'ProductWindow',
      component: () => import('views/ProductWindow'),
      meta: { requiresAuth: false }
    },
    {
      path: 'search',
      name: 'search',
      component: () => import('views/Search'),
      meta: { requiresAuth: true },
      children: [
        {
          path: 'exhibit/:id',
          name: 'exhibit',
          component: () => import('views/note/NotesDetails'),
          meta: { requiresAuth: true }
        },
        {
          path: 'exhibitor/:id',
          name: 'exhibitors',
          component: () => import('views/search/Exhibitors'),
          meta: { requiresAuth: true }
        }
      ]
    }
  ]
}];

const router = new VueRouter({
  mode: 'history',
  routes
});

Vue.use(VueAnalytics, {
  id: 'UA-38340381-2',
  router
});

router.beforeEach((to, from, next) => {
  next();
  if (to.name == 'login' && localStorage.getItem('user-token')) {
    store.dispatch('refreshTokenFunc').then(respStatus => {
      if(respStatus == 200)
        next({ name: 'menu' });
      else
        next({ name: 'login' });
    });
  }
  else {
    if(to.matched.some(record => record.meta.requiresAuth) && localStorage.getItem('user-token') === null) {
      next({ name: 'login' });
    }
    else {
      if (store.getters.isAuthorized) {
        switch(checkTokensStat()) {
        case -1:
          store.dispatch('authExpired').then(() => next({ name: 'login' }));
          break;
        case 0:
          store.dispatch('refreshTokenFunc').then(() => next());
          break;
        case 1:
          axios.defaults.headers.common['authorization'] = 'Bearer ' + localStorage.getItem('user-token');
          if (to.name == 'ausstelleransicht' && (!localStorage.getItem('user-roles').includes('exhibitor') && !localStorage.getItem('user-roles').includes('organizer'))) {
            next({ name: 'plan' });
          } else {
            next();
          }
          break;
        }
      }
      next();
    }
  }
});

function checkTokensStat(){
  var currentTime = new Date().getTime();
  var tokenExpiryDate = Number(localStorage.getItem('token-expires-in')) ;
  var refreshExpiryDate = Number(localStorage.getItem('refresh-expires-in'));

  if (currentTime > refreshExpiryDate) {
    return -1; // refresh token has expired redirect to login
  }
  else if (currentTime > tokenExpiryDate) {
    return 0; // refresh access token
  }
  else {
    return 1; // your tokens are ok!
  }
}

export default router;
