<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
export default {
  beforeDestroy() {
    this.$store.commit('closeWebsocket');
  }
};
</script>

<style lang="scss">
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;700&display=swap");
@import url("https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.css");

html {
  height: 100%;
  width: 100%;
  overflow: hidden;
}

body {
  height: 100%;
  padding: 0;
  overflow: auto;
  margin: 0;
  -webkit-overflow-scrolling: touch;
}

#app {
  font-family: Arial, Helvetica, sans-serif;
  font-weight: 400;
  height: 100%;
  width: 100%;
}

.grecaptcha-badge {
  opacity: 0 !important;
}

/* width */
::-webkit-scrollbar {
  width: 3px !important;
}

// /* Track */
// ::-webkit-scrollbar-track {
//   background: #3b3815;
//   display: none;
// }

/* Handle */
::-webkit-scrollbar-thumb {
  background: rgba(210, 10, 40, 1);
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: rgba(210, 10, 40, 1);
}
</style>
