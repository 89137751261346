import axios from 'axios';

const API_URL = process.env.VUE_APP_API_URL;

let axiosInstance = axios.create({
  baseURL: API_URL,
  headers: {
    'Access-Control-Allow-Origin': '*',
    'Content-Type': 'application/json'
  }
});

axiosInstance.interceptors.request.use(config => {
  return config;
}, (error) => {
  return Promise.reject(error);
});

axiosInstance.interceptors.response.use(
  response => { 
    return response; 
  },
  (error) => {
    if (!error.response) {
      // TODO: Route to 500 error page using window.loaction = '/route-path'
    }
    switch (error.response.status) {
    case 404:
      // TODO: Route to 500 error page using window.loaction = '/route-path'
      break;
    case 400:
      return error.response;
    default:
      break;
    }
    return Promise.reject(error);
  }
);

export default axiosInstance;
