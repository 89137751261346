import axios from './axios';
import store from '../store';
import router from '../router';

axios.interceptors.request.use(config => {

  if (store.getters.isAuthorized) {

    if (checkTokensStat() == -1){
      return store.dispatch('authExpired').then(() => {
        router.push('login');
        return Promise.reject();
      });
    }

    if (config.url == '/check-sso' && config.method == 'post') {
      return Promise.resolve(config);
    }

    if (checkTokensStat() == 0) {
      return store.dispatch('refreshTokenFunc')
        .then(() => {
          config.headers['authorization'] = 'Bearer ' + localStorage.getItem('user-token');
          return Promise.resolve(config);
        })
        .catch(err => {
          console.log(err);
          return Promise.reject(err);
        });
    }

  }
  return Promise.resolve(config);
}, (error) => {
  return Promise.reject(error);
});


function checkTokensStat() {
  var currentTime = new Date().getTime();
  var tokenExpiryDate = Number(localStorage.getItem('token-expires-in'));
  var refreshExpiryDate = Number(localStorage.getItem('refresh-expires-in'));

  if (currentTime > refreshExpiryDate) {
    return -1; // refresh token has expired redirect to login
  }
  else if (currentTime > tokenExpiryDate) {
    return 0; // refresh access token
  }
  else {
    return 1; // your tokens are ok!
  }
}
