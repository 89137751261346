import Vue from 'vue';
import Vuex from 'vuex';
import VueAxios from 'vue-axios';
import axios from '../plugins/axios';

Vue.use(Vuex);
Vue.use(VueAxios, axios);

export default new Vuex.Store({
  state: {
    user: {}
  },
  mutations: {
    async toggleAnonymous(state,payload) {
      console.log(state,payload);

      //   try {
      //     await this.$http.patch('/anonymous', { anonymous: anonymous });
      //   } catch (e) {
      //     return (anonymous = !anonymous);
      //   }
    }
  },
  actions: {
    toggleAnonymous(context) {
      context.commit('toggleAnonymous');
    }
  },
  getters: {}
});
