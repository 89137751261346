import '@fortawesome/fontawesome-free/css/all.min.css';
import 'bootstrap-css-only/css/bootstrap.min.css';
import 'mdbvue/lib/css/mdb.min.css';
import Vue from 'vue';
import './plugins/refreshToken';
import App from './App.vue';
import router from './router';
import store from './store';
import VueI18n from 'vue-i18n';
import messages from './translations';
import axios from './plugins/axios';
import VeeValidate from 'vee-validate';
// import { Validator } from 'vee-validate';
import VueDragResize from 'vue-drag-resize';
import VueRecaptcha from 'vue-recaptcha';
import VueAnalytics from 'vue-analytics';
import VueNativeSock from 'vue-native-websocket';

import linkify from 'vue-linkify';

Vue.use(require('vue-moment'));
 
Vue.directive('linkified', linkify);

Vue.use(VueNativeSock, 'wss://munis.b-rex.de/ws', {
  store: store,
  reconnection: true, // (Boolean) whether to reconnect automatically (false)
  reconnectionAttempts: 5, // (Number) number of reconnection attempts before giving up (Infinity),
  reconnectionDelay: 3000
});

VeeValidate.Validator.extend('customEmail', {
  getMessage() {
    return 'Bitte geben Sie eine gültige E-Mail Adresse ein';
  },
  validate(value) {
    const EMAILREG = /.*[@][a-zA-Z0-9-_.]+[.][0-9a-zA-Z]{2,6}$/;

    // console.log(EMAILREG.test(value));
    return EMAILREG.test(value);
  }
});

VeeValidate.Validator.extend('customPlz', {
  getMessage() {
    return 'Bitte geben Sie eine gültige Postleitzahl ein';
  },
  validate(value) {
    const PLZREG = /[0-9]{4,5}$/;

    // console.log(PLZREG.test(value));
    return PLZREG.test(value);
  }
});

Vue.use(VueAnalytics, {
  id: 'UA-38340381-2',
  checkDuplicatedScript: true
});

import browserDetect from 'vue-browser-detect-plugin';
Vue.use(browserDetect);

Vue.use(VueI18n);
Vue.use(VeeValidate);
Vue.component('vue-drag-resize', VueDragResize);
Vue.component('vue-recaptcha', VueRecaptcha);
Vue.config.productionTip = false;

const i18n = new VueI18n({
  locale: 'de',
  messages
});

const app = new Vue({
  router,
  store,
  axios,
  i18n,
  render: (h) => h(App)
});

store.$app = app;

app.$mount('#app');
