import Vue from 'vue';
import Vuex from 'vuex';
import VueAxios from 'vue-axios';
import axios from '../plugins/axios';
import auth from './auth';
import user from './user';
import chat from './chat';
import _ from 'lodash';

Vue.use(Vuex);
Vue.use(VueAxios, axios);

export default new Vuex.Store({
  state: {
    popup: false,
    modal: false,
    modalMessage: '',
    activeMenu: 1,
    modalIcon: '',
    modalClass: '',
    wsUri: 'wss://munis.b-rex.de/ws',
    socket: {
      isConnected: false,
      message: '',
      reconnectError: false
    },
    usersOnline: [],
    allUsers: [],
    unreplayed: [],
    isBusy: []
  },
  mutations: {
    async SOCKET_ONOPEN(state, event) {
      Vue.prototype.$socket = event.currentTarget;
      state.socket.isConnected = true;
      if (state.user) {
        let userResp = await axios.get('/users');
        let users = [...userResp.data.visitors, ...userResp.data.exhibitors];
        state.allUsers = users;
      }
    },
    SOCKET_ONCLOSE(state, event) {
      console.log(event);
      state.socket.isConnected = false;
    },
    SOCKET_ONERROR(state, event) {
      console.error(state, event);
    },
    // default handler called for all methods
    async SOCKET_ONMESSAGE(state, message) {
      let data = JSON.parse(message.data);
      if (data.wsMessageType == 'online-status') {
        if (data.status === 'offline') {
          state.usersOnline = state.usersOnline.filter(
            (user) => user.uuid !== data.user
          );
        } else {
          let user = await axios.get('/contact', {
            params: { user_uuid: data.user }
          });
          if (user.user_name !== '-') {
            state.usersOnline.push(user.data);
          }
          state.usersOnline = _.uniqBy(state.usersOnline, 'uuid');
        }
      }
      if (data.wsMessageType == 'e2e') {
        console.log('YOU GOT MAIL');
      }
    },
    // mutations for reconnect methods
    SOCKET_RECONNECT(state, count) {
      console.info(state, count);
    },
    SOCKET_RECONNECT_ERROR(state) {
      state.socket.reconnectError = true;
    },
    async getActiveUsers(state) {
      let userResp = await axios.get('/users');
      let users = [...userResp.data.visitors, ...userResp.data.exhibitors];
      state.allUsers = users;
      state.allVisitors = userResp.visitors;
      state.allExhibitors = userResp.exhibitors;
      let onlineListResp = await axios.get('/online-status-list');
      state.isBusy = onlineListResp.data.userList
        .filter((user) => {
          if (user.status) {
            return user.status === 'busy';
          } else return false;
        })
        .map((user) => user.user);
      let onlineList = onlineListResp.data.userList.map((user) => user.user);
      let map = users.filter((user) => onlineList.includes(user.uuid));
      state.usersOnline = map;
    },
    closeWebsocket() {
      if (window.websocket) {
        window.websocket.close();
        window.websocket = null;
      }
    }
  },
  actions: {
    sendMessage: function(context, message) {
      console.log(context, message);
    },
    getActiveUsers: (context) => {
      context.commit('getActiveUsers');
    }
  },
  getters: {},
  modules: {
    auth,
    user,
    chat
  }
});
